import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  file: File | null;
  progress: number;
  isUploading: boolean;
  uploadComplete: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIdReportUpload : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      file: null,
      progress: 0,
      isUploading: false,
      uploadComplete: false,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } 
        if( apiRequestCallId === this.apiCallIdReportUpload) {
          toast.success("SIMAH Report Uploaded");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

  };

  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "application/pdf") {
      this.setState({ file, isUploading: true, uploadComplete: false }, this.simulateUpload);
    }
  };

  simulateUpload = () => {
    const interval = setInterval(() => {
      this.setState((prevState):any => {
        if (prevState.progress >= 100) {
          clearInterval(interval);
          return {
            progress: 100,
            isUploading: false,
            uploadComplete: true,
          };
        }
        return {
          progress: prevState.progress + 10,
        };
      });
    }, 300);
  };

  apiCallFnSimahReport = async () => {
    const loginToken = await getStorageData("login_token");
  
    const formData = new FormData();
    
    if (this.state.file) {
      formData.append("user_application_document[document_file]", this.state.file);
    }
  
    const header = {
      token: loginToken,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiCallIdReportUpload = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndpointSimahReport
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return this.apiCallIdReportUpload;
  };
  
  // Customizable Area End
}
