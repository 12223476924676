// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Container,
  Typography,
  styled,
  Grid,
  Box,
  Button,
  LinearProgress
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import CustomFormController, {
  Props,
} from "./CustomFormController.web";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const images = require("./assets");
const text = require("./config");
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainDiv>
        <Typography
          variant="h6"
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center', cursor: "pointer" }}
          data-test-id="home-btn-logo"
          onClick={() => this.onPressMenuItem("Home")}
        >
          <img src={images.HeaderLogo.default} alt="Header Logo" />
        </Typography>

        <Grid container className="mainPageContent">
          <Grid item className="uploadSimah">
            <img className="simahLogo" src={images.SimahReportLogo.default} alt="Simah Report" />
            <Typography className="uploadSimahReportText">{text.uploadSimahReportText}</Typography>
          </Grid>

          <Box sx={{ position: "relative", width: "520px" }}>

            {this.state.isUploading && (
              <LinearProgress
                variant="determinate"
                value={this.state.progress}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "4px",
                  backgroundColor: "#E2E8F0",
                  borderRadius: "10px",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#8833FF",
                  },
                }}
              />
            )}
            <Box className="uploadBtnBox" >
              <div>
                {!this.state.uploadComplete ? (
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img src={images.UploadIcon.default} style={{ marginRight: "8px" }} />
                    <Box>
                      <Typography variant="body1" component="span">
                        Click here to Upload
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        PDF
                      </Typography>
                    </Box>
                  </label>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {this.state.file?.name}
                    </Typography>
                    <MoreHorizIcon />
                  </Box>
                )}
              </div>
              <input
                id="file-upload"
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                data-test-id="fileUpload"
                onChange={this.handleFileChange}
              />
            </Box>
          </Box>

          <Button data-test-id="nextBtn" onClick={this.apiCallFnSimahReport} className={`${this.state.progress === 100 ? "nextBtn" : "disabledNextBtn"}`} disabled={!this.state.file}>
            {text.nextBtnText}
          </Button>

        </Grid>
      </MainDiv>
      // Customizable Area End
    );
  }
}

const MainDiv = styled(Container)({
  flex: 1,
  padding: 16,
  backgroundColor: "#ffffffff",
  width: "70%",
  "& .mainPageContent": {
    marginTop: "108px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  "& .uploadSimah": {
    textAlign: "center",
    marginBottom: "42px"
  },
  "& .simahLogo": {
    marginBottom: "23px"
  },
  "& .uploadSimahReportText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "28px",
    color: "#000000"
  },
  "& .uploadBtnBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "8px",
    marginBottom: "29px"
  },
  "& .nextBtn": {
    backgroundColor: "#1D36BB",
    textTransform: "capitalize",
    borderRadius: "6px",
    color: "white",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Poppins",
    width: "47%",
    "&:hover": {
        backgroundColor: "#1D36BB", 
    },
  },
  "& .disabledNextBtn": {
    backgroundColor: "#F1F5F9",
    textTransform: "capitalize",
    borderRadius: "6px",
    color: "#808080",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Poppins",
    width: "45%"
  }
})
// Customizable Area End
